import arrayFrom from 'array-from';

import { addClass, removeClass } from '../../../../assets/javascript/tools/helpers';

class ModalOpener {
  constructor(options = {}) {
    this.element = options.element;
    this.modalRef = this.element.dataset.modal;
  }

  init() {
    if (this.modalRef) {
      this.element.addEventListener('click', () => {
        this.closeAllModals();
        this.openModal(this.modalRef)
      });

      document.querySelector(`#${this.modalRef}`).querySelector('button.close').addEventListener('click', () => {
        this.closeAllModals();
      })
    }
  }

  closeAllModals() {
    arrayFrom(document.querySelectorAll('.modal.shown')).forEach((modal) => {
      removeClass(modal, 'shown');
    });
  }

  openModal(refName) {
    const element = document.querySelector(`#${refName}`);
    addClass(element, 'shown');
    document.querySelector(`#${this.modalRef}`).querySelector('button.close').focus();
  }
}

export default ModalOpener;