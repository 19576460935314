import { addClass } from "../../../../../assets/javascript/tools/helpers";

class FurtherAction {
  constructor(options = {}) {
    this.element = options.element;
    this.closeButton = this.element.querySelector('.mod--further-action__close');
  }

  init() {
    console.log('Further Action Init', this.closeButton);
    this.closeButton.addEventListener('click', () => {
      addClass(this.element, 'hidden');
    });
  }
}

export default FurtherAction;