import arrayFrom from 'array-from';
import { addClass, hasClass, removeClass } from '../../../../../assets/javascript/tools/helpers';

class WineList {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    this.triggerElements = arrayFrom(this.element.querySelectorAll('button'));
    this.contentElements = arrayFrom(this.element.querySelectorAll('.mod--wine-list__category__content'));

    this.triggerElements.forEach((button) => {
      button.addEventListener('click', () => {
        const refContent = this.element.querySelector(`#${button.id.split('-')[1]}`);
        const refContentShownBefore = hasClass(refContent, 'shown');

        this.contentElements.forEach((element) => {
          removeClass(element, 'shown');
        });

        this.triggerElements.forEach((element) => {
          removeClass(element, 'active');
        });

        if (!refContentShownBefore) {
          addClass(refContent, 'shown');
          addClass(button, 'active');
        }
      });
    });
  }
}

export default WineList;